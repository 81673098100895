<template>
  <div class="content-raw-mtaterial-detail global-content-detail-all">
    <div class="global-content-detail">
      <div ref="iHeaderDetail">
        <header-detail-component-global
          sTextDetail="Detalle de:"
          sBackHref="RawMaterial"
        />
        <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
      </div>
      <general-information-raw-material-component
        @setBasePurchasePriceMXN="setBasePurchasePriceMXN"
        @setBasePurchasePriceUSD="setBasePurchasePriceUSD"
        @setBaseSalePriceMXN="setBaseSalePriceMXN"
        @setBaseSalePriceUSD="setBaseSalePriceUSD"
        class="content-general-information-component"
      />
      <base-price-component
        class="contant-base-price-component mb-9"
        :dBasePurchasePriceMXN="dBasePurchasePriceMXN"
        :dBasePurchasePriceUSD="dBasePurchasePriceUSD"
        :dBaseSalePriceMXN="dBaseSalePriceMXN"
        :dBaseSalePriceUSD="dBaseSalePriceUSD"
      />
      <inventory-component class="contant-inventory-component mb-9" />
      <inputs-outputs-component class="content-inputs-outputs-component mb-9" />
      <transaction-history-component
        class="content-transaction-history-component mb-2"
      />
    </div>
    <!-- <footer-component-global /> -->
  </div>
</template>
<script>
export default {
  name: "RawMaterialDetail",
  data() {
    return {
      screenHeight: 0,
      heightMax: 0,
      itemsBreadcrumbs: [
        {
          text: "Materias primas",
          disabled: false,
          to: { name: "RawMaterial" },
        },
        {
          text: "Detalle de materia prima",
          disabled: true,
          to: { name: "" },
        },
      ],
      dBasePurchasePriceMXN: 0,
      dBasePurchasePriceUSD: 0,
      dBaseSalePriceMXN: 0,
      dBaseSalePriceUSD: 0,
    };
  },
  mounted() {
    this.matchHeight();
    this.handleResize();
  },
  updated() {
    this.matchHeight();
  },
  beforeMount() {
    this.$store
      .dispatch("getPermissionsByUserGlobal")
      .then((resp) => {})
      .catch((err) => {
        this.Error(err);
      });
  },
  created() {
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.addEventListener("resize ", this.matchHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    matchHeight() {
      if (this.$refs.iHeaderDetail !== undefined) {
        this.heightMax = this.$refs.iHeaderDetail.clientHeight;
        this.heightMax = this.heightMax + 123;
      }
      if (window.innerWidth > 600) {
        this.screenHeight = window.innerHeight - 100;
      } else {
        this.screenHeight = window.innerHeight - 100;
      }
    },
    handleResize: function () {
      this.matchHeight();
      // if (window.innerWidth > 600) {
      //   this.screenHeight = window.innerHeight - this.heightMax;
      // } else {
      //   this.screenHeight = window.innerHeight - this.heightMax;
      // }
    },
    setBasePurchasePriceMXN(dBasePurchasePriceMXN) {
      this.dBasePurchasePriceMXN = dBasePurchasePriceMXN;
    },
    setBasePurchasePriceUSD(dBasePurchasePriceUSD) {
      this.dBasePurchasePriceUSD = dBasePurchasePriceUSD;
    },
    setBaseSalePriceMXN(dBaseSalePriceMXN) {
      this.dBaseSalePriceMXN = dBaseSalePriceMXN;
    },
    setBaseSalePriceUSD(dBaseSalePriceUSD) {
      this.dBaseSalePriceUSD = dBaseSalePriceUSD;
    },
  },
};
</script>

<style scoped>
.content-general-information-component {
  padding: 0px 0px 0px 12px;
}

.contant-base-price-component {
  padding: 0px 0px 0px 12px;
}

.contant-inventory-component {
  padding: 0px 0px 0px 12px;
}

.content-inputs-outputs-component {
  position: relative !important;
  padding: 0px 0px 0px 12px;
}

.content-transaction-history-component {
  /* padding: 0px 0px 0px 12px; */
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-general-information-component {
    padding: 0px 0px 0px 0px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
